@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
  overscroll-behavior: none;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
}

html {
  font-size: 100%;
  overscroll-behavior: none;
  overscroll-behavior-y: none;
}

@media (max-width: 768px) {
  html {
    font-size: 90%;
  }
}

* {
  box-sizing: border-box;
}

label {
  font-weight: 600;

}

.container {
  margin-top: 100px;
}

.input_space {
  margin-bottom: 10px;
}

input {
  height: 30px;
  width: 20%;
  text-align: left;
  font-size: 0.875rem;
  line-height: 0.875;
  font-weight: 600;
  color: #151515;
}

button {
  background-color: grey;
  border: none;
}

.logout {
  margin-right: 5px;
  color: white;
  font-weight: 400;
}

.delete {
  margin-right: 5px;
  color: white;
  background-color: rgb(19, 103, 142);
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.navbar {
  padding: 15px 25px !important;
  background: #263658 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 0px #2c406a;
  position: fixed !important;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
  z-index: 11;
  border: none;
  border-radius: 0;
}

.navbar::before,
.navbar::after {
  display: none !important;
}

.navbar ul {
  display: flex;
  gap: 10px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.zone select {
  padding: 0 25px 0 36px;
  background: transparent url("./assets/images/location.png") no-repeat 10px center;
  background-size: contain;
  border: none;
  text-align: left;
  font-size: 0.8rem;
  line-height: 1.1;
  letter-spacing: 0px;
  color: #e3e7f1;
  border-left: 2px solid #485a7d;
  cursor: pointer;
  user-select: none;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  z-index: 1;
  position: relative;
}

.zone select option {
  color: #263658
}

button.toprightbtn {
  background-color: #f17456;
  border-radius: 4px;
  border: none;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer !important;
  transition: all 0.4s;
}

button.toprightbtn:hover,
button.toprightbtn.active,
button.toprightbtn.show,
button.toprightbtn:active,
button.toprightbtn:focus,
button.toprightbtn:focus-visible {
  background-color: #ff6640 !important;
}

.topRightCard {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.topRightCard img {
  width: 30px;
  height: 15px;
  border-radius: 50%;
  color: #d61f1f;
}

.topRightCardText {
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: left;
}

.topRightCardText select {
  background: transparent;
  border: none;
  color: var(--unnamed-color-e3e7f1);
  text-align: left;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.0625;
  color: #e3e7f1;
  cursor: pointer;
  outline: none;
}

.topRightCardText p {
  margin: 0;
  color: #A4AFC7;
  text-align: left;
  line-height: 1.8;
  font-size: 0.75rem;
}

.main {
  padding-top: 68px;
  width: 100%;
  background: #f6f9f9 0% 0% no-repeat padding-box;
}

.contentCard {
  background: #263658;
  position: relative;
  overflow: hidden;
}

.cardbg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: multiply;
}

.cards {
  padding: 0px 20px 30px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  z-index: 1;
}

.customcard {
  background: #314264 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #141e364d;
  border: 1px solid #394b6d;
  /* width: 350px; */
  padding: 25px 20px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  min-height: 315px;
  margin: 0 5px;
}

ul.cardTable {
  margin: 0;
  padding: 0;
  height: 80px;
}

.cardTable li {
  display: flex;
  list-style: none;
  width: 100%;
  font-size: 0.75rem;
  line-height: 1.8;
  color: #a4afc7;
  /* align-items: center; */
  justify-content: space-between;
}

.cardTable span {
  text-align: right;
  font-weight: 600;
  display: flex;
  align-items: end;
}

.customcard h4 {
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3;
  height: 50px;
  margin-bottom: 15px;
}

.customcard h4::before {
  position: absolute;
  content: "";
  width: 2.5px;
  bottom: 6px;
  left: -22px;
  top: 6px;
  background: #fff;
}

.customcard h4.dipping::before {
  position: absolute;
  content: "";
  width: 3px;
  bottom: 6px;
  left: -23px;
  top: 6px;
  background: #f17456;
}

.customcard h4.Healthcard::before {
  background: #28c9ad;
}

.customcard h4.planthealthcard::before {
  background: #d5ad28;
}

.customcard h4.livestokservice::before {
  background: #27f364;
}

.customcard h4.livestockslt::before {
  background: #e6ca56;
}

.customcard h4.cropDisease::before {
  background: #D5AD28;
}

.customcard h4.Meatinsp::before {
  background: #FF4E90;
}

.customcard h4.PoultryLog::before {
  background: #D25AFC;
}

.customcard h2 {
  text-transform: uppercase;
  margin-top: 0px;
  font-weight: 600;
}

.customcard p {
  margin: 0;
  color: #e3e7f1;
  line-height: 0.8;
  /* margin-top: 5px; */
  margin: 0;
}

.viewbtn {
  text-align: center;
  position: absolute;
  bottom: -18px;
  left: 0;
  right: 0;
  display: flex;
}

.viewbtn a {
  padding: 10px 20px 15px;
  border-radius: 30px;
  border: none;
  background: #223251 0% 0% no-repeat padding-box;
  box-shadow: 0px -1px 0px #394b6d;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.5;
  color: #5b6a85;
  text-transform: uppercase;
  text-decoration: none !important;
  display: flex;
  /* flex-wrap: nowrap; */
  gap: 10px;
  width: auto;
  margin: 0 auto;
  align-items: center;
}

.viewbtn a p {
  display: flex;
  flex-shrink: 0;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.5;
  color: #5b6a85;
}

.dipping {
  color: #f17456;
  width: 80%;
}

.Healthcard {
  color: #28c9ad;
  width: 80%;
}

.planthealthcard {
  color: #d5ad28;
  width: 80%;
}

.livestokservice {
  color: #27f364;
  width: 50%;
}

.livestockslt {
  color: #e6ca56;
  width: 50%;
}

.Meatinsp {
  color: #FF4E90;
  width: 50%;
}

.PoultryLog {
  color: #D25AFC;
  width: 40%;
}

.cropDisease {
  color: #D5AD28;
  width: 80%;
}


.cardContentHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  color: #7382a5;
}

.cardcontentHeaderRight {
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 1;
  cursor: pointer;
  user-select: none;
}

.leftArrow {
  padding: 12px 10px;
  background: #31436a 0% 0% no-repeat padding-box;
  margin: auto;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightArrow {
  padding: 12px 10px;
  background: #31436a 0% 0% no-repeat padding-box;
  margin: auto;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardcontentHeaderRight .active {
  background: #f17456 0% 0% no-repeat padding-box;
  color: white;
}

/* ========Cards ====================== */
.cardHeader {
  background: #f6f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 0px #f1f5f5;
  padding: 10px 15px;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #2d373a;
  align-items: center;
  min-height: 60px;
}

.headerbtn button {
  background: transparent;
  border: none;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #263658;
  text-transform: uppercase;
  cursor: pointer;
}

.headerbtn a {
  color: #263658;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 1.5;
  text-transform: uppercase;
}

.themeCard {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 6px #40b1d91a;
  overflow: hidden;
  text-align: center;
  transition: all 0.2s;
  box-shadow: 0px 0px 30px #DFE9E9CC;
  border: 1px solid #DFE9E9;
  /* height: 400px; */
  position: relative;

}

.cardTitle {
  /* font: normal normal bold 18px/26px Poppins; */
  font-size: 1.125rem;
  line-height: 1.625;
  font-weight: bold;
  color: #1A2743;
  ;
  margin: 0;
}

.card-body {
  width: 100%;
  height: 100%;
  min-height: 310px;
  align-items: center;
  text-align: center;
  background: #fff;
  /* padding: 0 0 20px !important; */
}

.card-body.perform {
  /* min-height: 412px; */
  position: relative;
  display: grid;
}

.card-body.revanues {
  /* min-height: 412px; */
  position: relative;
}

.card-body img {
  width: 33%;
}

/* .cardRow1 {
  margin: 20px 0;
  display: grid;
  grid-template-columns: 31% auto;
  gap: 40px;
} */

/* .cardRow2 {
  margin: 20px 0;
  display: flex;
  gap: 40px;
} */

.footer {
  width: 100%;
  align-items: center;
  text-align: center;
  color: #8b8b8b;
  padding: 15px;
  margin: 0;
  background: #f6f9f9 0% 0% no-repeat padding-box;
}

.footer p {
  margin: 0;
}

.add {
  position: relative;
}

.addMore {
  background: white;
  display: none;
  position: absolute;
  top: 30px;
  width: 200px;
  z-index: 5;
  padding: 10px 0px 5px 10px;
  border-radius: 10px;
  overflow: hidden;
}

.addMore ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  gap: 0px;

}

.addMore a {
  text-align: left;
  text-decoration: none;
  line-height: 1.3;
  font-size: 0.9rem;
  color: #263658;
  width: 117px;
  width: 100px;
  text-decoration: none !important;
  white-space: nowarap;
  width: 100%;
  white-space: normal;
  display: block;
}

.addMore ul li {
  width: 100%;
  padding: 0 20px;
  text-align: left;
}

.addMore a:hover {
  background: #fff1ee 0% 0% no-repeat padding-box;
  border: none;
  outline: none;
}

.addMore a.active {
  background: #fff1ee 0% 0% no-repeat padding-box !important;
  margin-right: -20px;
}

/* ============page 2 style start==================================================================== */
.middle {
  position: relative;
  margin-top: 30px;
}

/* .petCard {
  display: flex;
  gap: 10px;
} */
.petCard {
  display: flex;
  width: 165px;
  gap: 10px;
  height: 33px;
}

.aboutPet {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0;
}

.petName {
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  line-height: 0.8;
  color: #8b8b8b;
}

.petCount {
  text-align: left;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 0.9;
  color: #1a2743;
  text-transform: uppercase;
}

.politryStock1 {
  position: relative;
  opacity: 1;
  display: flex;
  z-index: 1;
  border-radius: 5px;
  min-height: 233px;
}

.politryStockForm {
  position: relative;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 30px #2636581a;
  opacity: 1;
  margin-top: -70px;
  border-radius: 5px;
}


.politryStock {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: white;
  padding: 20px 20px 50px;
  border-radius: 5px 5px 0 0px;
  min-height: 60vh;
}

.poltryTop {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.TotalLiv {
  text-align: left;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0px;
  color: #03bf9e;
  opacity: 1;
}

.TotalLivCount {
  text-align: left;
  font-size: 1.65rem;
  font-weight: 600;
  line-height: 0.9;
  letter-spacing: 0px;
  color: #03bf9e;
  text-transform: uppercase;
}

.politryStockCard1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  background-color: white;
  padding: 30px 37px;
  border-radius: 5px 0 0 5px;
  position: relative;
}

.politryStockCard2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  background-color: white;
  padding: 30px;
  border-left: 2px solid #f6f9f9;
  border-radius: 0 5px 5px 0;
  position: relative;
}

.TotalEgg {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0px;
  color: #f17456;
  opacity: 1;
}

.TotalEggCount {
  text-align: left;
  font-size: 1.65rem;
  font-weight: 600;
  line-height: 0.9;
  letter-spacing: 0px;
  color: #f17456;
  text-transform: uppercase;
  opacity: 1;
}

/* .petCard img {
  width: 60px;
  height: 50px;
} */

/* .politryStockCardContent {
  gap: 15px 80px;
  display: grid;
  grid-template-columns: auto auto auto;
} */
.politryStockCardContent {
  gap: 15px 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  min-height: 100px;
}

/* ============page 2 style End==================================================================== */

/* =====================page 3 style start============================================================ */
.theme-container {
  padding: 0 20px;
  min-height: calc(100vh - 120px);
}

.pageTitle {
  font-weight: 600;
  font-size: 1.45rem;
}

.contentCard {
  background: #263658;
  position: relative;
  overflow: hidden;
  padding: 20px;
  margin: 0 -20px;
}

.contentCard2 {
  background: #263658;
  position: relative;
  overflow: hidden;
  height: 150px;
  padding: 20px;
  margin: 0 -20px;
}

.contentCard h3 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.65;
  color: #ffffff;
}

.cardbg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: multiply;
}

/* ========Page 3 style start  ====================== */
.contentCardHeader {
  display: flex;
  justify-content: space-between;
}

.addProdctHeader {
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.65;
  color: #ffffff;
}

.contentCardHeader button {
  background: transparent;
  border: none;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #f17456;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1;
}

.AddprodRow2 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  width: 100%;
  text-align: left;
  font-size: 0.82rem;
  line-height: 0.88;
  letter-spacing: 0px;
  color: #7a7a7a;
  opacity: 1;
  padding-bottom: 20px;
  border-bottom: 2px solid #f1f1f1;
}

.inputBoxes {
  align-items: center;
  width: 100%;
}

.inputAdd {
  margin-top: 20px;
  display: flex;
  border: solid 1px #ccc;
  overflow: hidden;
  border-radius: 6px;
  width: 100%;
  height: 44px;
}

.inputAdd .inputAddLabel {
  display: flex;
  align-items: center;
  text-align: center;
  background: #F1F3F8;
  /* color: #000; */
  padding: 10px 15px;
  text-align: left;
  font-size: 0.875rem;
  line-height: 0.88;
  color: #8b8b8b;
  flex-shrink: 0;
  margin: 0;
}

.inputAdd .inputAddLabelWait {
  display: flex;
  align-items: center;
  text-align: center;
  background: #f1f1f1;
  color: #000;
  padding: 10px 9px;
  flex-shrink: 0;
  text-align: left;
  font-size: 0.88rem;
  line-height: 0.88;
  color: #8b8b8b;
  margin: 0;
}

.inputAdd input {
  display: flex;
  align-items: center;
  text-align: left;
  background: #fff;
  color: #151515;
  padding: 4px 15px;
  border: solid 1px #fff;
  outline: none;
  box-shadow: none;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 0.875;
}

.AddprodRow1 {
  display: flex;
  gap: 45px;
}

.AddprodRow1Egg {
  display: flex;
  /* gap: 100px; */
  justify-content: space-between;
}

.farmersBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  font-size: 0.81rem;
  line-height: 0.88;
  color: #7a7a7a;
}

.farmers {
  display: flex;
  border: solid 1px #ccc;
  overflow: hidden;
  border-radius: 2px;
  gap: 1px;
  width: 260px;
  height: 44px;
}

.farmersbtn {
  display: flex;
  float: right;
}

.farmers label {
  align-items: center;
  text-align: center;
  background: #f1f1f1;
  color: #000;
  padding: 15px;
  margin: 0;
  user-select: none;
  cursor: pointer;
}

.farmers input {
  display: flex;
  align-items: center;
  text-align: left;
  background: #fff;
  color: #000;
  padding: 4px 9px;
  border: solid 1px #fff;
  outline: none;
  box-shadow: none;
  width: 70%;
}

.farmers input.celender {
  width: 100%;
}

.eggLogFarmer {
  display: flex;
  gap: 35px;
}

.buttons {
  display: flex;
  gap: 20px;
  /* height: 44px; */
  flex-wrap: wrap;
}

.drainagebtns {
  height: auto;
  padding-bottom: 20px;
}

.buttons button:focus-visible {
  outline: none;
}

.buttons button {
  padding: 10px 20px;
  flex-wrap: nowrap;
  flex-shrink: 0;
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dae6;
  text-align: left;
  font-size: 0.88rem;
  font-weight: 600;
  line-height: 0.88;
  color: #151515;
  height: 44px;
}

.drainagebtns {
  padding: 15px 20px;

}

.AddprodRow3 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 0.8rem;
  line-height: 0.88;
  color: #7a7a7a;
}

.prodctRow {
  border-top: 2px solid #f1f1f1;
  /* padding: 0px 0 10px 0; */
  /* height: 60px; */
  display: flex;
}

.addprodctRow {
  /* border-top: 2px solid #f1f1f1; */
  padding: 0px 0 10px 0;
  /* height: 60px; */
  /* display: flex; */
}

.prodctRowinputBoxes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.prodctRow .prodctRowCol1 {
  font-size: 0.88rem;
  font-weight: 600;
  line-height: 0.88;
  color: #151515;
  align-items: center;
  padding: 10px;
  width: 150px;
  display: flex;
  justify-content: left;
  margin-top: 20px;
}

.prodctRow .drainagecolLabel {
  width: 200px;
  line-height: 1;
}

.prodctRow .prodctRowCol2 {
  font-size: 0.88rem;
  font-weight: 600;
  line-height: 0.88;
  color: #151515;
  align-items: center;
  padding: 10px;
  display: flex;
  justify-content: left;
  margin-top: 20px;
}

.addprodctRow .prodctRowCol2 {
  font-size: 0.88rem;
  font-weight: 600;
  line-height: 0.88;
  color: #151515;
  align-items: center;
  padding-top: 10px;
  display: flex;
  justify-content: left;
  margin-top: 20px;
}

.bottomButtons {
  padding: 20px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  box-shadow: 0px -1px 0px #efefef;
  display: flex;
  gap: 10px;
  border-radius: 0 0 5px 5px;
}

.bottomButtons .addprdt {
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  background: #e6e6e6 0% 0% no-repeat padding-box;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.25;
  color: #263658;
  cursor: pointer;
}

.bottomButtons .reset {
  background: #e6e6e6 0% 0% no-repeat padding-box;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.25;
  color: #263658;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.bottomButtons button.active {
  background: #263658 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2636584d;
  color: #ffffff;
}

.custom_radio input[type="radio"] {
  display: none;
}

.custom_radio input[type="radio"] + label {
  position: relative;
  display: inline-block;
  padding-left: 1.5em;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.custom_radio input[type="radio"] + label:before,
.custom_radio input[type="radio"] + label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  text-align: center;
  color: white;
  font-family: Times;
  border-radius: 50%;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.custom_radio input[type="radio"] + label:before {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
  border: solid 1px #ccc;

}

.custom_radio input[type="radio"] + label:hover:before {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em #F17456;
  border: solid 1px #F17456;
}

.custom_radio input[type="radio"]:checked + label:before {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #F17456;
  border: solid 1px #F17456;
  color: #F17456;
}

.custom_radio input[type="radio"]:checked + label {
  color: #F17456;
}


/* .buttons button:hover, */
.buttons .active {
  background: #f174561a 0% 0% no-repeat padding-box;
  border: 1px solid #f17456;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 0.875;
  color: #f17456;
}

.farmersbtn label.active {
  background: #f17456 0% 0% no-repeat padding-box;
  /* border: 1px solid #f17456; */
  font-size: 0.88rem;
  font-weight: 600;
  line-height: 0.88;
  color: rgb(242, 242, 242);
  user-select: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.formatDis {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  font-size: 0.81rem;
  font-weight: 600;
  line-height: 0.88;
  color: #7a7a7a;
}

.formatDis select,
input {
  width: 260px;
  height: 44px;
  text-align: left;
  font-size: 0.88rem;
  font-weight: 600;
  line-height: 0.88;
  color: #151515;
  padding: 0 10px;
}

.farmersCelender input {
  width: 230px;
  height: 44px;
  text-align: left;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 0.9;
  letter-spacing: 0px;
  color: #151515;
  opacity: 1;
}

input::placeholder {
  font-weight: 400;
  color: #cecece;
}

.applyButton {
  opacity: 1;
  display: flex;
  gap: 10px;
  border-radius: 5px;
  align-items: end;
}

.applyButton button {
  border-radius: 5px;
  border: none;
  padding: 7px 35px;
  background: #e6e6e6 0% 0% no-repeat padding-box;
  text-align: center;
  font-size: 0.81rem;
  font-weight: 600;
  line-height: 1.25;
  color: #263658;
  cursor: pointer;
  background: #263658 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2636584d;
  color: #ffffff;
  height: 44px;
}

.tablebox {
  margin-top: 20px;
}

.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: #fbfcff;
}

table.table > tbody > tr.bgblue {
  background-color: #717e9a;
  color: #fff;
}

table.table > tbody > tr.bgorange {
  background: #f8f0ee 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 0px #eceaea;
  font-size: 0.88rem;
  font-weight: 600;
  line-height: 1.375;
  color: #bf665e;
}


table.table > tbody > tr.bgwhite {
  background: white 0% 0% no-repeat padding-box;
  opacity: 1;
  font-size: 0.88rem;
  font-weight: 600;
  line-height: 1.375;
}

.indication {
  text-align: left;
  font-size: 0.88rem;
  font-weight: 600;
  line-height: 1.375;
  color: #151515;
}

.tpara {
  text-align: left;
  font-size: 0.7;
  line-height: 0.9;
  color: #8b8b8b;
  opacity: 1;
}

.tdTotal {
  text-align: left;
  font-size: 1rem;
  font-weight: 650;
  line-height: 1.375;
  letter-spacing: 0px;
  color: #151515;
  opacity: 1;
}

section {
  padding: 20px 0;
}

.ui-tabs .ui-tabs-nav {
  margin: 0;
  padding: 10px !important;
  display: flex;
  justify-content: space-around;
}

.ui-tabs .ui-tabs-panel {
  display: block;
  border-width: 0;
  padding: 0 !important;
  background: none;
}

.ui-tabs .ui-tabs-panel {
  display: block;
  border-width: 0;
  padding: 0;
  background: none !important;
}

li.tabbtn {
  background: none !important;
  outline: none;
}

/* =============home page bottom table===================================================   */



table.homepageTable td {
  text-align: center;
}

.table-bordered > tbody > tr > td,
/* .table-bordered>tbody>tr>th, */
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
/* .table-bordered > thead > tr > th,  */
.table-bordered > thead > tr > td {
  border: 1px solid #E2EAEE;
  font-size: 0.75rem;
  text-align: center;
}

/* .table-bordered>thead>tr:nth-child(even) {
  background-color: #5a6a7a00 !important;
} */
.table-bordered > tbody > tr > td {
  font-size: 0.875rem;
  overflow: hidden;
  padding: 10px;
}

.table-bordered > thead > tr > th strong {
  font-weight: 600 !important;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #666A71;

}

.table-bordered > thead > tr > th {
  font-size: 0.75rem;
  color: #666A71;
  text-transform: uppercase;
  font-weight: 600 !important;
  background: #F6F9F9;
  border: none !important;
  padding: 15px 10px;
  outline: 1px solid #b9b7b7;
}

.table-bordered > thead > tr > th.eggMiddle {
  font-size: 0.688rem !important;

}

.ui-widget-header {
  border: none;
  background: rgb(233, 233, 233);
  color: rgb(51, 51, 51);
  font-weight: bold;
}

.ui-widget-content {
  border: none;
  background: #ffffff;
  color: #333333;
}

.ui-widget.ui-widget-content {
  border: none;
}

td.thd strong {
  line-height: 1.375;
  font-weight: 600;
  color: #666a71;
  text-transform: uppercase;
}

td.epmlName strong {
  width: 150px;
}

/* th.meatinspHead{
  text-align: center !important;
  padding-left: 10px !important;
} */
th.meatinspheadLeft {
  /* text-align: left !important; */
  padding-left: 10px;
}

td.epmlName {
  text-align: left !important;
}

td.employeeName {
  text-align: left !important;
  color: #263658;
  font-weight: 600;
  padding: 8px 10px;
  min-width: 140px;
  text-transform: capitalize !important;

}

tr td.dates {
  /* text-align: left !important; */
  font-size: 0.875rem !important;
  line-height: 1.375;
  color: #8B8B8B;
  opacity: 1;
  min-width: 130px;
}

tr td.EggType {
  text-align: left !important;
  font-size: 0.875rem;
  line-height: 1.375;
  font-weight: 500;
  color: #151515;
}

tr td.xlLarge {
  /* width: 480px; */
  width: auto;
}

tr.homepagetbl td {
  text-align: right;
  font-size: 0.875rem !important;
  line-height: 1.375;
  font-weight: 600;
  color: #666a71;
  text-transform: uppercase;
  /* font-size:0.9rem;
     font-weight:600;
     line-height:0.9; */
}


td.tblTotal {
  /* text-align: right; */
  font-size: 0.875rem;
  line-height: 1.375;
  color: #151515;

}


td.thd {
  background: #f1f1f1 0% 0% no-repeat padding-box;
  text-align: center;
}

/* .alignmd {
  text-align: center;
} */
td.thd.alignmd {
  vertical-align: middle;
  text-align: center !important;
  line-height: 1.125;
  font-weight: 600;
  padding-left: 15px;
  color: #666A71;
}

td.thd.emplName {
  text-align: left !important;
}

/* th.alignmdleft, */
td.alignmdleft {
  text-align: left !important;
  padding-left: 10px;
}

.ui-tabs .ui-tabs-nav {
  margin: 0;
  padding: 0 10px !important;
  display: flex;
  justify-content: space-between;
  background: none;
  outline: none;
}

.alignmdcntr {
  text-align: center !important;
}

.animaltype {
  /* text-align: left !important; */
  padding: 8px 10px;
  text-align: left !important;
}

.animaltypes {
  padding: 8px 10px;
  padding-left: 18px;
  text-align: left !important;
}

td.thd.aligntblright {
  text-align: right !important;
}


.ui-tabs .ui-tabs-panel {
  display: block;
  border-width: 0;
  padding: 0 !important;
  background: none;
}

.ui-tabs .ui-tabs-panel {
  display: block;
  border-width: 0;
  padding: 0;
  background: none;
}

/* li.tabbtn{
font-weight: normal;
color: #454545;
background: none; 
border: none;
} */
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: none;
  background: none;
  font-weight: normal;
  color: #454545;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  border: none;
  background: none;
  font-weight: normal;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 600;
  color: #f17456;
  outline: none;
  border-bottom: 2px solid red;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  text-decoration: none;
  border-bottom: 2px solid #f17456;
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 600;
  color: #f17456;
}

a:focus {
  color: none;
  text-decoration: underline;
  outline: none;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: none;
  background: #f6f6f6;
  font-weight: normal;
  color: #454545;
  background: none;
}

ul.tabbuttons li .a {
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 600;
  color: #263658;
}

.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: #fff;
}

/* ######################## Tabs ################# */
.navTabs {
  background: #fff;
  transition: all 0.4s;
}

.tabbuttons {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s;
  margin: 0 35px;
}


.tabbuttons li {
  flex: 1;
  transition: all 0.4s;
}

.tabbuttons li .tbBtn {
  text-decoration: none;
  display: block;
  padding: 15px 20px;
  text-align: center;
  transition: all 0.4s;
  border-bottom: solid 2px transparent;
  height: 60px;
  font-size: 0.875rem;
  font-weight: 600;
}

.tabbuttons li .tbBtn:hover,
.tabbuttons li.active .tbBtn {
  color: #f17456;
  border-bottom: solid 2px #f17456;
}

/* ######################## Tabs End ################# */

.sidemenuHead {
  display: flex;
  gap: 5px;
  border-bottom: 2px solid rgba(158, 157, 157, 0.251);
  margin-top: 29px;
}

.sidemenuheadImage {
  height: 30px;
  width: 30px;
}

.sideMenuanimal {
  height: 30px;
  width: 30px;
}

.sidemenuCrops {
  display: flex;
  gap: 5px;
}

.sidemenuCrops img {
  height: 30px;
  width: 30px;
}

.croptext {
  color: #22c7aa;
  font-size: 1rem;
  font-weight: 500;
  padding-top: 5px;
  margin-bottom: 0;
}

.sidemenuAnimaltext {
  color: #c47169;
  font-size: 1rem;
  font-weight: 500;
  padding-top: 5px;
}

.sidemenuReporttext {
  color: #dab745;
  font-size: 1rem;
  font-weight: 500;
  padding-top: 5px;
}

.w3-sidebar {
  position: absolute;
  top: 0px;
  left: 0;
  width: 450px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 80px;
}

.w3-sidebar select.active,
a.active,
option.active {
  color: rgb(244, 152, 48) !important;
}

.w3-bar-block .w3-bar-item {
  text-align: right !important;
  background: #ffffff;
}

.selectArrow {
  margin-top: -20px;
}

.arrow {
  border: solid #E3E7F1;
  border-width: 0 2px 2px 0;
  display: block;
  padding: 3px;
  cursor: pointer;
  float: right;
  margin-top: 7px;
  position: relative;
  /* margin-left: -45px; */
  right: 15px;
  z-index: 0;
}

.fltright {
  float: right;
  user-select: none;
  margin-right: 13px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.up {
  margin-top: 11px;
  transform: rotate(-135deg) 1s;
  -webkit-transform: rotate(-135deg);
}

.sideMenu {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.sideMenu li a {
  text-decoration: none;
  /* padding: 0px 20px 0 0; */
  color: #000;
}

.sideMenu li {
  position: relative;
  width: calc(100% - 55px);
  text-align: left;
  border-bottom: 2px solid rgba(158, 157, 157, 0.251);
  padding: 0px 0px 0px 10px;
  text-decoration: none;
  margin: 0 10px 0 20px;
}


ul.subMenu {
  display: none;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  text-align: left;
}

ul.subMenu li {
  width: 370px;
  border: none;
  margin: 0;
  border-left: none;
}

.sideLink a {
  width: 100%;
}

.sideLink {
  cursor: pointer;
}

.sideLink label {
  cursor: pointer;
  text-align: left;
  /* font: normal normal medium 13px/40px Poppins; */
  font-size: 1rem;
  line-height: 2.5;
  font-weight: 550;
  color: #263658;
  display: inline-block;
  width: calc(100% - 15px);
}

.sideMenu li a {
  text-decoration: none;
  padding: 15px 20px 15px 0px;
  line-height: 2.5;
  font-weight: 550;
  color: #263658;
  display: block;
}

.sideMenu .sublinks a {
  text-decoration: none;
  padding: 0px 20px 0 0;
  font-size: 1rem;
  line-height: 2.5;
  font-weight: 500;
  color: #263658;
}

.sideMenu li.link.active {
  color: #F17456 !important;
  border-bottom: 2px solid rgba(249, 140, 85, 0.441);
}

.sideMenu li.link.active label {
  color: #F17456 !important;
}

.sideMenu li.link.active::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 40px;
  left: -30px;
  top: 5px;
  background: #F17456;
}

.sideMenu li.a.active,
.sideMenu li .sublinks a:hover {
  color: #F17456;
  background: #f9926d28;

}

ul.subMenu li.active {
  color: #d14e07;
  background: #f9926d28;
}

.sideMenu li.active ul.subMenu {
  display: block;
}

.heading3 {
  color: #1A2743;
  font-size: 1.5rem;
  font-weight: 700;
}

.loginPage {
  margin-top: -70px;
  /* padding: 100px 200px; */
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

.loginPageLeft {
  position: relative;
  width: 33%;
  background: transparent url("../src/assets/images/loginleftbg.png") 0% 0% repeat padding-box;
  padding: 40px 30px;
  text-align: left;
}

.loginPageRight {
  padding: 120px 0 0 150px;
  position: relative;
  width: 100%;
  background: #f6f9f9;
  display: flex;
}

.imgbg {
  width: 410px;
  height: unset;
  position: absolute;
  opacity: 1;
  z-index: 1;
  bottom: -45px;
  right: 0px;
  color: #fff;
  filter: grayscale(1);
}

.imgbg .loginRightbg {
  max-width: 100%;
}

.loginlogo {
  text-align: left;
  margin-bottom: 50px;
}

.loginLeftbg img {
  width: 400px;
  height: auto;
}

.loginLeftbg {
  position: absolute;
  opacity: 0.5;
  overflow: hidden;
  top: 0;
  z-index: -555;
}

.loginleftText {
  text-align: left;
}

.loginleftText p {
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2;
  letter-spacing: 0px;
  color: #f17456;
  margin-bottom: 0;
}

.loginleftText h2 {
  font-size: 1.875rem;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: left;
}

.loginRightContent {
  text-align: left;
}

.loginRightContent h3 {
  text-align: left;
}

.loginRightContent p {
  text-align: left;
  margin-top: 15px;
}

.loginRightContent p a {
  color: #263658;
  text-decoration: none;
}

.loginUsrname {
  margin: 0;
  display: flex;
  border: solid 1px #ccc;
  overflow: hidden;
  border-radius: 4px;
  width: 400px;
  height: 44px;
  margin-top: 20px;
}

.loginUsrname input {
  border: none;
  outline: none;
  width: 100%;
}

.loginUsrname .loginUsrnameLabel {
  display: flex;
  align-items: center;
  background: #f1f1f1;
  padding: 10px 15px;
  text-align: left;
  line-height: 0.88;
  color: #8b8b8b;
  flex-shrink: 0;
  width: 130px;
  margin: 0;
  font-weight: 400;
}

.loginPageRight .password {
  margin-top: 35px;
}

.vissIcon {
  padding: 10px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.vissIcon img {
  user-select: none;
}

.forgottenPass {
  font-size: 0.875rem;
  line-height: 0.875;
  color: #263658;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  /* gap: 30px; */

}

.passEror {
  margin-top: -5px !important;
  margin-bottom: 5px;
  font-size: 0.875rem;
  line-height: 0.875;
  font-weight: 500;
  margin: 5px 0px;
  color: #e53844;
}

.loginbtn {
  text-align: right;
  width: 400px;
}

.passwordRightLogIn {
  display: flex;
  justify-content: space-between;
  text-align: right;
  width: 400px;
  align-items: flex-end;
}

.loginbtn button {
  padding: 8px 30px;
  background: #263658 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2636584d;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: .9rem;
  position: relative;
  z-index: 2;
}

.loginbtn button.disable {
  opacity: .5;
  cursor: not-allowed !important;
}

.emaileror {
  display: none;
}

.eror .loginUsrname {
  border: 1px solid #e53844;
}

.eror p {
  display: block;
  font-size: 0.875rem;
  line-height: 0.875;
  font-weight: 500;
  margin: 5px 0px;
  color: #e53844;
}

/* ===================Updated page css============================================ */

.addForm {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 30px #2636581a;
  min-height: 0;
}

.addFormPage {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.addFormRow input {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dae6;
  font-size: 0.875rem;
  /* line-height: 0.875; */
  font-weight: 600;
  /* color: #E5E5E5; */
  border-radius: 5px;
  margin-top: 8px;
}

.addFormRow select {
  width: 100%;
  height: 44px;
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dae6;
  border-radius: 5px;
  margin-top: 8px;
  font-size: 0.875rem;
  line-height: 0.875;
  font-weight: 600;
  padding: 0 10px;
}

.addFormRow label {
  width: 100%;
  text-align: left;
  font-size: 0.87125rem;
  line-height: 0.875;
  color: #7a7a7a;
  margin-bottom: 5px;
}

.addUserCheckbox {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
}

.addUserCheck {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0;
}

.addUserCheck p {
  margin: 0;
  padding: 0;
  font: normal normal 600 14px/14px Poppins;
  color: #151515;
}

.addUserCheckbox input {
  margin: 0;
  padding: 0;
  height: auto;
  width: auto;
}

.adduserbtn {
  margin-top: 8px;
  display: flex;
}

.adduserbtn button {
  padding: 3px 15px;
  border-radius: 5px;
  margin-right: 10px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dae6;
  font: normal normal 600 14px/14px Poppins;
  color: #151515;
  flex-shrink: 0;
}

.home-table {
  width: 100%;
}

.addExpense {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.addExpense select {
  width: 100%;
  height: 44px;
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dae6;
  border-radius: 5px;
  margin-top: 8px;
}

.add-user-password {
  width: 100% !important;
  margin: 0;
  display: flex;
  border: solid 1px #ccc;
  overflow: hidden;
  border-radius: 4px;
  width: 360px;
  height: 44px;
  margin-top: 8px;
  font-size: .875rem;
}

.add-user-password input {
  width: 100% !important;
  border: none;
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 30px #2636581a;
  font-size: .875rem;
}

.add-user-password button {
  background: #f1f3f8 0% 0% no-repeat padding-box;
}

label.adduser-password {
  width: 100%;
  text-align: left;
  font-size: 0.87125rem;
  line-height: 0.875;
  color: #7a7a7a;
  margin-bottom: 5px;
}

.addExpense label {
  width: 100%;
  text-align: left;
  font-size: 0.87125rem;
  line-height: 0.875;
  color: #7a7a7a;
  margin-bottom: 5px;
}

.addExpense input {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dae6;
  font-size: 0.875rem;
  line-height: 0.875;
  font-weight: 600;
  color: #e5e5e5;
  border-radius: 5px;
  margin-top: 8px;
}

.select-animal-type {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 43%;
}

.userMngmntBtn {
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  padding: 0px;
  background: transparent;
  text-align: center;
  align-items: end;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.25;
  color: #263658;
  height: 100%;
  outline: none;
  box-shadow: none;
}

.userMngmntBtn button.mngmntApplybtn {
  height: 44px;
  padding: 5px 35px;
}

.usermngmntTable {
  width: 100%;
}

.usermngmntTable th {
  background: #eff0f1 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 0px #dce2e8;
  padding: 15px 6px;
  color: #666A71;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.375;
  text-align: center !important;
}

.usermngmntTable tbody tr:hover {
  background: #f9f5f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 0px #e2eaee;
}

.mngTblPass button {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mngTblPass button img {
  filter: saturate(5);
}

.mngTblPass img {
  margin-left: auto;
  cursor: pointer;
  user-select: none;
}

.c-pointer {
  cursor: pointer;
}

.userListtbl {
  text-align: left;
  padding-left: 13px !important;
  text-align: left;
  font-size: 0.75rem;
  line-height: 1.375;
  font-weight: 600;
  color: #666A71;
  text-transform: uppercase;
  background: #EFF0F1 0% 0% no-repeat padding-box;
}

.userListEmail {
  text-align: left !important;
  font-size: 0.875rem;
  line-height: 1.375;
  color: #263658;
}

.userListTbl td {
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.375;
  color: #3d3c3cd9;
  padding-left: 12px !important;
  font-weight: 500;
}

tbody tr:nth-child(even) {
  background-color: #FBFCFF !important;
}

.mngTblPass {
  display: flex;
  justify-content: space-between;
  background: transparent !important;
  align-items: center;
  width: 160px;
  font-weight: 600;
  width: 130px;
  max-width: 250px;
  overflow: hidden;

}

td.tablePassword {
  width: 250px;
  max-width: 250px;
}

.tableSwitchbtn {
  display: flex;
  gap: 15px;
  background: transparent !important;
  align-items: center;
  width: 135px;
}

.bg-transparent {
  background: transparent !important;
}

/* ================ slide Button code======================================== */
.switch {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 15px;
  margin: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: -2px;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  /* left: -2px; */
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #27ce05;
}

input:focus + .slider {
  box-shadow: 0 0 1px #27ce05;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* ========================slide button code end========================== */
.logOutBtn {
  margin-top: 20px;
  padding: 10px 30px;
  text-align: right;
  align-items: center;
}

.logOutBtn button {
  border-radius: 8px;
  background: #263658;
  color: #ffffff;
  padding: 5px 14px;
}

button.toprightbtn.addUserbtn {
  margin: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.08;
  font-weight: 600;
  color: #E3E7F1;
  text-transform: capitalize;
}

button.toprightbtn.addUserbtn:hover {
  background-color: transparent !important;
  background: none;

}

button.toprightbtn.addUserbtn:hover,
button.toprightbtn.addUserbtn.active,
button.toprightbtn.show.addUserbtn,
button.toprightbtn.addUserbtn:active,
button.toprightbtn.addUserbtn:focus,
button.toprightbtn.addUserbtn:focus-visible {
  background-color: transparent !important;
  background: none;
  outline: none;
  display: flex !important;
  gap: 10px;
  align-items: center;
  justify-content: left;
}

.addEggdlt {
  margin: 20px 0 0 20px;
  padding: 0px 30px !important;
}

.tableBlock {
  height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.homepgtable {
  height: 100%;
  overflow-y: auto;
}

.tableBlockPage {
  /* min-height: calc(100vh - 200px); */
  min-height: 418px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 350px; */
  height: calc(100vh - 230px);
  overflow-y: auto;
  position: relative;
}

.tablepgtbl {
  margin-top: 80px;
}

.cardSlider .owl-nav {
  position: absolute;
  top: -72px;
  right: 6px;
  display: flex;
  margin: 0;
  user-select: none;
  outline: none;
  outline-offset: none;
}

.owl-carousel.cardSlider .owl-nav button {
  background: #F17456 0% 0% no-repeat padding-box !important;
  border-radius: 3px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 30px;
  height: 30px;
  font-size: 2rem !important;
  font-weight: 200 !important;
  line-height: 1 !important;
  color: #fff !important;
  transition: all 0.4s;
}

.owl-carousel.bottomSlider .owl-nav button {
  background: #263658 0% 0% no-repeat padding-box !important;
  border-radius: 3px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 30px;
  height: 30px;
  font-size: 2rem !important;
  font-weight: 200 !important;
  line-height: 1 !important;
  color: #fff !important;
  transition: all 0.4s;
}


.owl-carousel.cardSlider .owl-nav button.disabled {
  background: #31436a 0% 0% no-repeat padding-box !important;
  color: #676880 !important;
  transition: all 0.4s;
  display: flex !important;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: flex !important;
}

.owl-carousel.cardSlider .owl-nav button.owl-next span,
.owl-carousel.cardSlider .owl-nav button.owl-prev span {
  margin-top: -3px !important;
  outline: none;
  outline-offset: none;


}

.owl-carousel.cardSlider .owl-nav button.owl-next:focus-visible,
.owl-carousel.cardSlider .owl-nav button.owl-prev:focus-visible {
  outline: none;
}

.owl-carousel.cardSlider.bottomSlider .owl-nav button.owl-next:focus-visible,
.owl-carousel.cardSlider.bottomSlider .owl-nav button.owl-prev:focus-visible {
  outline: none;
}

.owl-carousel.cardSlider .owl-nav button:hover {
  background: #F17456 !important;
  color: #fff !important;

}


.owl-carousel.cardSlider.bottomSlider .owl-nav button:hover {
  background: #263658 !important;
  color: #fff !important;
}

.owl-carousel.cardSlider.bottomSlider .owl-nav button span {
  user-select: none;
}

.owl-carousel.cardSlider.bottomSlider .owl-nav button.disabled {
  background: #EFF0F1 0% 0% no-repeat padding-box !important;
  color: #676880 !important;
  transition: all 0.4s;
  display: flex !important;
}



.w3-bar-block .w3-bar-item img {
  width: 17px;
}

.addUserCheck label {
  color: #151515;
}

.shimmertext {
  color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 20px 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}


.customcard .shimmertext {
  padding-right: 40px;
}

.revanues .shimmertext {
  padding-bottom: 70px;
}

.loader {
  width: 4rem;
  height: 4rem;
  border: 3px solid transparent;
  border-radius: 50%;
  display: inline-block;
  /* position: relative; */
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
  /* margin-top: 30px; */
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #FF3D00 transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.addProductbox label {
  width: 35%;
  text-align: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.addproductionRadiobuttons {
  display: flex;
  gap: 20px;
}

.addproductionRadiobuttons input {
  width: 18px;
  margin: 0;
}

.addproductionRadiobuttons label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.addRadio {
  display: flex;
  font-size: 0.875rem;
  line-height: 0.875;
  font-weight: 600;
  color: #151515;
}

.addRadio.active,
.addRadio.active input {
  font-size: 0.875rem;
  line-height: 0.875;
  font-weight: 600;
  color: #F17456;
  ;
}

p.schoolAgricltr {
  text-align: left;
  font-size: 0.8rem;
  line-height: 0.875;
  font-weight: bold;
  color: #151515;
  padding-bottom: 20px;

}

.addschool {
  border-bottom: 2px solid #f1f1f1;
}

.notfoundDatatext {
  display: flex;
  /* min-height: 280px; */
  text-align: center;
  justify-content: center;
  align-items: center;
  width: auto;
  margin: 0 auto;
  margin: 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 99%;

}

.incReport {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.incReport select,
input {
  width: 100%;
  height: 44px;
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dae6;
  border-radius: 5px;
  font-size: 0.875rem !important;
  line-height: 0.875;
  font-weight: 600;
}

td.cliTotal {
  text-align: center;
  font-weight: 600;
  color: #000;
}

.incReport label {
  font-size: 0.8rem;
  line-height: 0.875;
  color: #7A7A7A;
  /* font-weight: normal; */
}

.incomeReportTable th {
  /* text-align: center !important;
  font-size: 0.75rem;
  line-height: 1.375;
  font-weight: 600 !important;
  color: #666A71;
  text-transform: uppercase;
  border: 1px solid #000; */
  padding: 10px;
  line-height: 1.375;
  font-size: 0.75rem;
  color: #666A71;
  text-transform: uppercase;
  font-weight: 600 !important;
  background: #F6F9F9;
  border: none !important;
  outline: 1px solid #b9b7b7;
}

.incomeReportTable th strong {
  font-size: 0.75rem;
  font-weight: 600 !important;
}

.incomeReportTable td {
  font-size: 0.875rem;
  line-height: 1.4;
  padding: 10px 15px;
  text-align: center;
}

.amount,
.amount2,
.income-date {
  font-size: 1rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.4;
  color: #151515;
  width: 130px;

}

.amount2 {
  min-width: 160px;
}


.incomeEmail {
  color: #263658;
  text-align: left !important;
}

.incomeNotes {
  text-align: left !important;
  font-size: 0.688rem !important;
  line-height: 1;
  font-weight: normal;
  color: #8B8B8B;
  max-width: 300px !important;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  text-overflow: ellipsis;
  /* height: 33px; */
  margin-right: 0;
}

.reporttableComment {
  max-width: 220px;
}

.incomeNotes:hover {
  height: unset;
  overflow: visible;
  text-overflow: unset;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  display: block;
}

.amountDescription {
  text-align: left !important;
  line-height: 0.875;
  font-weight: normal;
  flex-shrink: 3;
  /* max-width: 250px; */
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  text-overflow: ellipsis;
  /* height: 33px; */
  margin-right: 0;
  width: 200px;
}

.amountDescription:hover {
  height: unset;
  overflow: visible;
  text-overflow: unset;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  display: block;
}

.incomeReportTable th.incomereportTableHead {
  text-align: center;
}

.totalIncome .ttlincomedata {
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.375;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #151515;
}

.totalIncome .incometotaltext {
  text-align: center !important;
  font-size: 1.125rem;
  line-height: 1.375;
  font-weight: medium;
  color: #151515;
}

tr.totalIncome {
  background: #DDEEEB 0% 0% no-repeat padding-box !important;
}

.schoolUnit {
  display: flex;
  gap: 25px;
}

.AddPlantHealthbtn {
  height: auto;
}

.schoolLabel {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  padding-left: 30px;
}

.addschoolRow {
  border-top: 2px solid #f1f1f1;
}

.CardContainer {
  height: 100%;
}

.usrlsttbl {
  min-height: 0px;
  border-radius: 5px;
}

.usermngntPageTable {
  margin-top: 8px;
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 10px;
}

.owl-themelist .owl-nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  padding: 0 10px;
  z-index: -1;
  margin: 0 -45px;
}

.viewbtn a {
  display: flex;
  flex-wrap: nowrap;

}

.viewMOreArrow {
  width: 20px !important;
  height: 10px;
}

.sublinks {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sideMenu .sublinks a {
  padding: 10px 0 10px 10px;
  margin-left: -10px;
}

.sideMenu .sublinks a.active {
  background-color: rgba(255, 185, 142, 0.183);
  color: #F17456;

}

td.eggType {
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.375;
  font-weight: normal;
  color: #151515;
}

td.eggTotal {
  text-align: right;
  font-size: 0.875rem;
  line-height: 1.375;
  font-weight: 600;
  color: #151515;
}

.addProdctHeader h3 {
  text-align: left;
  font-size: 1.5rem;
  line-height: 1.625;
  font-weight: bold;
  color: #FFFFFF;
}

p.footerText {
  font-size: 0.75rem;
  line-height: 1, 125;
  font-weight: 300;
  color: #8B8B8B;
}

span.agrofarms {
  /* font: normal normal medium 12px/18px Poppins; */
  font-size: 0.75rem;
  line-height: 1.125;
  font-weight: normal;
  color: #8B8B8B;
}

.mngpass {
  width: auto;
}

.fsproduct {
  padding-top: 20px;
}

.fsproduct .prodctRowCol1 {
  width: 300px;

}

.foodsecurityRadioinput {
  display: flex;
  gap: 10px;
  width: auto;
}

.foodsecurityRadioinput input {
  display: flex;
  width: 22px;
  height: 28px;
}

.addincomeForm {
  min-height: 0;
}

.fullbtn {
  height: auto;
}

.prodctRow .formFullLabel {
  width: 280px;
  line-height: 1.25;
}




.sidedrawerclosebtn img {
  cursor: pointer;
}


/* .schoolUnit{
  margin-top: 8px;
} */
.projectInput {
  width: 100%;
  height: 44px;
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dae6;
  border-radius: 5px;
  margin-top: 8px;
  font-size: 0.875rem;
  line-height: 0.875;
  font-weight: 600;
  padding: 0 10px;
}

label.subcatageryId {
  margin-top: 30px;
  margin-bottom: 10px;
  /* text-align: left; */
  font-size: 0.81rem;
  line-height: 0.88;
  color: #7a7a7a;
}

/* .indicatorType{
font-weight: 600;
line-height: 0.88;
color: #545353;
} */
.pleaseSelectindic {
  margin-top: 30px;
}

.chartCard {
  display: flex;
  width: 100%;
  height: auto;
  gap: 25px;
}

.revanueCard {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 6px #40b1d91a;
  overflow: hidden;
  text-align: center;
  transition: all 0.2s;
  box-shadow: 0px 0px 30px #DFE9E9CC;
  border: 1px solid #DFE9E9;
  min-height: 400px;
  width: 660px;
  height: 50vh;
}

.themechartCard {
  height: 100%;
}

.performanceCard {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 6px #40b1d91a;
  overflow: hidden;
  text-align: center;
  transition: all 0.2s;
  box-shadow: 0px 0px 30px #DFE9E9CC;
  border: 1px solid #DFE9E9;
  min-height: 400px;
  width: 100%;
  height: 50vh;

}

#chart {
  max-height: 100%;
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}

.cardRow {
  display: flex;
  gap: 35px;
}

.card1 {
  width: calc(50% - 30px)
}

/* .dates.alignmds{
  align-items: center !important;
} */
.marketAccessform {
  /* gap: 0; */
}

.mrktaccesbtns {
  margin-bottom: 25px;
}

.tablePagetop {
  height: 95px;
}

.gapLeftclass {
  padding: 0 0 0 25px !important;
}

.gapclass {
  padding: 0 12px !important;
}

.gaprightclass {
  padding: 0 25px 0 0 !important;
}


.addformsuccess {
  display: flex;
  gap: 15px;
  background: #00C851 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 10px #00C85166;
  padding: 10px 50px 10px 30px;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: left;
  font-size: 0.875rem;
  line-height: 0.875;
  font-weight: 600;
  color: #FFFFFF;
  border-radius: 5px;
  position: absolute;
  right: 40%;
  bottom: 50px;
}

.link.singleLink a {
  /* width: 200px !important; */
  display: block;
  padding: 16px 0 16px 10px;
  margin-bottom: -2px;
  margin-right: -10px;
  margin-left: -10px;
}

/* .sideLink label:hover, */
.link.singleLink a:hover,
.link.singleLink a.active {
  color: #F17456 !important;
  background: #f9926d28;
  border-bottom: 2px solid rgba(249, 140, 85, 0.441);
  padding-bottom: 14px;
}

.link.singleLink a:hover,
.sideLink label:hover,
.sideLink label:active,
.sideLink label.active,
li.sideLink:hover {
  color: #F17456 !important;
}

.sideLink {
  cursor: pointer;
  /* padding: 15px 0 15px 0; */
  margin-right: -10px;
  display: flex;
}

.addDrainageForm {
  height: 70px;
}

.schoolLabel {
  margin-top: 10px;
}

.planthealthbox {
  min-height: 65vh;
}

.addEggRows {
  margin-top: 20px;
}

.forgotPasswordsubmitbtn {
  margin-top: 20px;
}

.rstpassword {
  margin-top: 25px !important;
}

section.middle-bottom {
  margin: 0 13px;
}

a.sideline {
  margin-right: 10px;
}

a.homelogo {
  margin-right: 10px;
}

strong {
  color: #666A71 !important;
}

.loginfooter {
  position: absolute;
  right: 20px;
  bottom: 10px;
}

ul.sideMenu {
  gap: 0;
}

/* 
.owl-themelist .owl-next .disabled {
  display: none;
} */

.owl-carousel.cardSliderbtn .owl-nav button {
  cursor: pointer !important;
  z-index: 1;
  filter: saturate(1.5);
}

.owl-carousel.cardSliderbtn .owl-nav button.owl-prev.disabled img,
.owl-carousel.cardSliderbtn .owl-nav button.owl-next.disabled img {
  /* opacity: 0; */
  filter: contrast(0);
  outline: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}


.tablenotfound {
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  right: 0;
}


.incomeTableDataNotFound {
  position: relative;
  /* left: calc(265%); */
  width: 150px;
}

.meatinspRadio {
  width: 15px;
}

.meatinspRadiobtn label {
  user-select: none;
}

.meatinspRadiobtn .meatinspRadio:focus-visible,
.meatinspRadiobtn .meatinspRadio:focus {
  outline: none;
  border: none;
}


/* .performance .apexcharts-legend {
  background-color: transparent;
    display: inline-flex;
    left: unset !important;
    top: -40px !important;
    z-index: 99 !important;
}
.performance foreignObject {
  overflow: visible;
} */

@media (min-width:1300px) {
  .card-body {
    min-height: 350px;
  }

}

@media (min-width:1400px) {
  .imgbg {
    width: unset;
    height: 460px;
    bottom: 0;
  }

  .copy {
    bottom: 15px;
    right: 65px;
  }

}

.copy {
  position: absolute;
  bottom: 65px;
  z-index: 99;
  color: #8B8B8B;
  font-size: .8rem;
  right: 65px;
}


/* .performance .apexcharts-legend {
  background-color: transparent;
    display: inline-flex;
    left: unset !important;
    top: -40px !important;
    z-index: 99 !important;
}
.performance foreignObject {
  overflow: visible;
} */

@media (min-width:1400px) {
  .imgbg {
    width: unset;
    height: 460px;
    bottom: 0;
  }

  .copy {
    bottom: 15px;
    right: 65px;
  }

  .card-body {
    min-height: 380px;
  }


}

.meatinspRadiobtn {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 5px;
}

.meatinspRadiobtn label {
  margin-top: 8px;
  font-size: 0.875rem;
}

.check {
  border-radius: 7px;
  min-width: 320px;
  white-space: nowrap;
  text-align: left;
  display: inline-flex;
  align-items: center;
  /* padding: 0 35px; */
  /* background-color: #fffaff; */
  cursor: pointer;
  box-shadow: 0px 0px 35px 0 rgba(0, 0, 0, 0.15);
  color: var(--purple);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  /* min-height: 85px; */
  animation: drop 1s linear;
  overflow: hidden;
  /* font-size: 1.8rem; */
  height: 50px;
  width: fit-content;
  color: #fff;
}



.check.danger {
  background: red;
}

.check:before {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  background: #581f63;
  bottom: 0px;
  animation: grow 2s linear;
}

.check.danger:before {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  background: red;
  bottom: 0px;
  animation: grow 2s linear;
}

.check:before {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  background: #581f63;
  bottom: 0px;
  animation: grow 2s linear;
}

.check.danger:before {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  background: red;
  bottom: 0px;
  animation: grow 2s linear;
}

@keyframes grow {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.copy {
  position: absolute;
  bottom: 65px;
  z-index: 99;
  color: #8B8B8B;
  font-size: .8rem;
  right: 65px;
}

h5.addboxtitle {
  font-size: 0.87rem !important;
}

.toprightbtn2 {
  cursor: default !important;
}

.apexcharts-menu-icon {
  position: relative;
  top: -40px;
  right: 12px;
}

.addFormRow textarea {
  width: 100%;
  height: 70px;
  padding: 10px 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dae6;
  font-size: 0.875rem;
  line-height: 0.875;
  font-weight: 600;
  /* color: #E5E5E5; */
  border-radius: 5px;
}

.apexcharts-toolbar {
  z-index: 1 !important;
}

.apexcharts-menu {
  position: absolute;
  top: -10px !important;
  right: 0 !important;
}

.user-dropdown {
  display: inline-block;
  width: 50px !important;
}

.user-dropdown a {
  text-align: center;
}

.tablepgtpfix {
  position: fixed;
  width: 100%;
}

.hideth {
  display: none;
}

.fieldOfficerZone {
  padding: 0 25px 0 36px;
  background: transparent url("./assets/images/location.png") no-repeat 10px center;
  background-size: contain;
  border: none;
  text-align: left;
  font-size: 0.8rem;
  line-height: 1.1;
  letter-spacing: 0px;
  color: #e3e7f1;
  border-left: 2px solid #485a7d;
  cursor: pointer;
  user-select: none;
  box-shadow: none;
  outline: none;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  background: #fff1ee 0% 0% no-repeat padding-box !important;
  color: #263658;
  text-decoration: none;
  outline: 0;
}

.dropdown-menu > .active > a:focus-visible {
  background-color: none;
}

.zone select i.arrow {
  border: solid #E3E7F1 !important;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.zone select i.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.zone select i.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.tablePageHeader {
  display: flex;
  flex-direction: column;
}

.tablePageButtons {
  display: flex;
  justify-content: space-between;
  /* margin-top: -10px; */
}


.dateRangebtn {
  border-radius: 5px;
  padding: 6px 15px;
  margin-right: 15px;
  border: none;
  background-color: transparent;
  text-align: center;
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.4s;
  outline: none;
  -webkit-user-select: none;
  user-select: none;
  box-shadow: 2px 2px 2px 2px #ffffff24;
}


.dateRangebtn:focus,
.dateRangebtn:focus-visible {
  outline: none;
  border: none;
}

.datePopup {
  position: absolute;
  top: 60px;
  right: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  box-shadow: 1px 1px 1px 1px #d2d2d27a;

}

.datehide {
  display: none;
}

.dateRangeLabel {
  font-size: 0.81rem !important;
  line-height: 0.88;
  color: #7a7a7a;
}

/* tbody {
  display: block !important;
  height: 350px !important;
  width: 100% !important;
  overflow: scroll !important;
} */

@media (min-width: 1500px) {
  .card-body {
    min-height: 430px;
  }


}

@media (min-width: 1600px) {
  .card-body {
    min-height: 480px;
  }


}

@media (min-width: 2000px) {
  .card-body {
    min-height: 600px;
  }


}

.politryStockFormtbl {
  box-shadow: none;
  padding: 0;
  margin-top: -60px;
}

.tableThemeCard {
  box-shadow: none !important;
}

.tablePagesection {
  padding: 0 !important;
}

table {
  max-height: 200px !important;
  overflow-y: auto;
}

thead {
  position: sticky;
  top: 0;
  z-index: 0;
}

thead * {
  position: relative;
  z-index: 1;
}

.datefilterbtnbox {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.datefilterapllybtn {
  border-radius: 6px;
  padding: 5px 15px;
  background-color: #0b73db !important;
  text-align: center;
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.4s;
  outline: none;
  -webkit-user-select: none;
  user-select: none;
}

.checkBoxPopupbtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5dae6;
  font-size: 0.875rem;
  line-height: 0.875;
  font-weight: 600;
  /* color: #E5E5E5; */
  border-radius: 5px;
  margin-top: 8px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  align-items: center;
}

.checkBoxPopupbtn i {
  margin-top: 0px;
}

.multiselect {
  position: absolute;
  width: 250px;
  padding: 20px 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  box-shadow: 1px 1px 1px 1px #d2d2d27a;
  z-index: 5;
}

.dropdown-option {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d2d2d2;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  /* justify-content: space-between; */
  overflow: hidden;
  border: none;
  height: 25px
}

.dropdown-option input {
  width: 15px;
  margin-bottom: 5px;

}

label.dropdown-option {
  font-size: 0.875rem !important;
  font-weight: normal;
  line-height: 2.5;
  text-align: left;
  color: #151515;

}

label.dropdown-option:hover {
  background: #fff1ee 0% 0% no-repeat padding-box;
}

.loginUsrname.password.eror {
  border: 1px solid #e53844;
}

.incomeNotesbox {
  max-width: 250px;
}

button.fadeDownload {
  position: relative;
  z-index: 0;
  opacity: 0.5;
}

.reporttablePage {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #F6F9F9;
}

.adduserErorText {
  display: none;
  color: red;
  font-size: 0.875rem;
}

.incomeformEmailEror input {
  border: 1px solid #e53844;
}

.incomeformEmailEror .adduserErorText {
  display: block;
}

/* .incomeformEmailEror label {
  color: #eb2e2e;
} */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.paginantionBox {
  display: flex;
  justify-content: center;
  justify-content: center;
  background: #f1f1f1;
  padding: 7px;
}

.perPageBox {
  position: absolute;
  right: 60px;
}

.perPageBox select {
  border: 1px solid #ddd;
  background: transparent;
  outline: 0;
  border-radius: 4px;
}

.commentMarkbtn {
  /* margin-left: 5px; */
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
  text-align: right;
}

.btn-primary.commentMarkbtn:focus,
.btn-primary.commentMarkbtn:active,
.btn-primary.commentMarkbtn:hover,
.commentMarkbtn:focus-visible {
  outline: none !important;
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
}

img.commentMark {
  height: 14px;
  border-radius: 50%;
  margin-bottom: 3px;
  margin-left: 0;
}

.agrotablepage {
  max-height: 480px;
}

.date-select-text {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.date-select-text .active {
  color: #F17456;
}

.poultrystockpageTable {
  position: relative;
  height: 450px !important;
}

.revanue {
  position: absolute;
  top: -40px;
  bottom: 0;
  width: 100%;
}

.performance {
  position: absolute;
  top: -40px;
  bottom: 0;
  width: 100%;
}

.foodsecurityaddform {
  margin-top: 20px;
  padding-top: 0;
}

.foodlabel {
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 20px;
  font-size: 0.88rem;
  font-weight: 600;
  color: #151515;
}

.foodsecbtn {
  margin-top: 20px;
}

img.chartDownloadbtn {
  color: #000;
  filter: brightness(0) contrast(2) grayscale(1);
  cursor: pointer;
  position: absolute;
  top: 17px;
  right: 15px;
}

.revanuedateRangetop {
  position: relative;
  top: 20px;

}

.stockpagebtns {
  margin-top: -10px;
}

.homelogo img {
  height: 30px;
}

.offcanvas-body {
  height: calc(100vh - 70px);
}

.carousel .carousel-status {
  position: relative;
  bottom: 0;
  left: 0;
  right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.logoutText {
  /* color: #d61f1f; */
  /* margin-left: 15px; */
  display: flex;
  gap: 5px;
  margin-bottom: 0;
  align-items: center;
}

.btn-primary.active,
.btn-primary:active,
.btn:focus-visible,
.btn-primary:hover,
.btn:focus-visible,
.btn-primary:focus,
.open > .dropdown-toggle.btn-primary {
  background-color: #0d6efd !important;
  box-shadow: none !important;
  outline: none !important;
  /* border: none; */
}

.sidemenu-label {
  padding-top: 16px;
  padding-bottom: 16px;
  display: block;
  cursor: pointer;
  margin: 0;
  border-bottom: 2px solid transparent;

}

.sidemenu-label:hover {
  color: #F17456 !important;
  /* border-bottom: 2px solid rgba(249, 140, 85, 0.441); */
}

.foodSecurityTablePage {
  min-height: 0;
  height: calc(100vh - 210px) !important;
  background: #fff;
  width: 100%;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  margin-bottom: 0;
}


.planthealthDeletebtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.planthealthdltbutton {
  padding: 8px 25px !important;
}

.forgotPasswordlink {
  display: flex;
  width: 135px;
}

.sideLink.collapse.show a:hover,
.sideLink.collapse.show a.active {
  background: #f9926d28;
  color: #F17456 !important;
  display: block;
  padding: 15px 0;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
}

.poultryLogEggCount {
  display: flex;
}

.colourCard {
  display: flex;
  flex-direction: column;
  gap: 15px;
}


/* 
.foodsecuritybackAnddwn {
  margin-top: -10px;
} */


.foodsecIndicators {
  text-align: left !important;
  font-weight: 600 !important;
  color: #151515 !important;
}

td.fsTotal {
  font-weight: 600 !important;

}

td.foodsecMedicalIndicators {
  background: #F8F0EE !important;
  color: #BF665E !important;
  font-weight: 600 !important;
  text-align: left !important;
}

td.medicalDrug {
  background: #717E9A !important;
  text-align: left !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.downloadBtns {
  margin-top: -10px;
  display: flex;
  gap: 10px;
  margin-right: 4px;
}

button.downloadtblbtn {
  color: #fff !important;
  padding: 0px 20px;
  border: 1px solid #b0a4a4cc;
  border-radius: 20px;
  /* box-shadow: 2px 2px 2px 2px #b0a4a4cc; */
  background: transparent;
  margin-top: -10px;
  display: flex;
  gap: 5px;
  /* padding-top: 8px; */
  align-items: center;
  height: 35px;

}

.dwlnldIcon {
  height: 15px;
  /* filter: contrast(0.1) brightness(1); */
  filter: invert(100%);
  margin-bottom: 5px;
}

.reportTabledwnBtn {
  margin-top: -10px;
}

.recommended {
  font-size: 0.875rem !important;
  color: #000 !important;
  max-width: 200px;
}

.sublink {
  display: flex;
  flex-direction: column;
}

body.modal-open {
  padding-right: 0 !important;
}

button.view_location_btn {
  margin: 8px 5px;
}